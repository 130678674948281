import React, { useEffect, useState } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Svg,
  Polyline,
} from '@react-pdf/renderer'
import { AccreditReports, PdfData } from '../../../../types/accredit-reports/AccreditReportsTypes'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import PdfTable from './PdfTable'
import PerformanceCheckTable from './PerformanceCheckTable'
import PerformanceCheckTable1 from './PerformanceCheckTable1'
import {
  CalculateUncertaintyForRepeatability,
  FormatConditionAsText,
  StandardDeviation,
  StandardDeviationVer2,
  UncertaintyCalculationVer2,
} from '../helper/PdfStylingCheck'
import moment from 'moment'

// Create styles
const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    padding: 26,
  },
  logo: {
    marginLeft: 'auto',
    marginTop: 20,
    marginRight: 'auto',
    width: 150,
    height: 30,
  },
  title: {
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  domain: {
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 16,
    textTransform: 'uppercase',
    fontFamily: 'Helvetica-Bold',
  },
  mark: {
    position: 'absolute',
    right: 30,
    top: 80,
    width: 110,
    height: 60,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 6,
  },
  border_section: {
    border: '1px solid black',
    paddingRight: 6,
    paddingLeft: 6,
    marginBottom: 4,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
})
type Props = {
  data: PdfData
}

const pdf_report = {
  statement_scale_class:
    '* Weight device accuracy class as determined from NIST Handbook 44-2000, table 3. Class III scale tolerance is from NIST Handbook 44 table 6. Class I and II device tolerance are 1/4 of the 0.1% tolerance of the applied load as stated in the United States Pharmacopoeia (USP) code or 3 divisions, whichever is larger.',
  statement_units_of_measure:
    '** All units of measure are consistent throughout this calibration report unless otherwise noted.',
  cornerload_note:
    '*next : All AS LEFT mass values must be in-tolerance for span and cornerload performance checks as a whole to be in-tolerance. The determination in/out of tolerance takes into account the measurement uncertainty.',
  calibration_note:
    'This report may not be reproduced, except in full, without the written permission from NBS Calibrations. Calibration procedures follow ISO 17025-2017 and NBS Calibrations procedure PR004. Copies of this procedure and calibration reports for the standards used are available upon request.',
  calibration_note_ynA2LACalRep:
    'The standards used to calibrate your equipment are traceable to the SI and NIST through the State of Minnesota Metrology Lab. The expanded measurement uncertainty meets or exceeds the 4:1 ratio of the stated tolerance (TUR). The TUR was calculated using the expanded measurement uncertainty with a coverage factor of k=2 and an approximate level of 95% confidence. The uncertainty value includes components for the resolution of the instrument receiving the calibration, the standard deviation of the repeatability test or an equivalent component and the standard uncertainty of the standards used to perform the calibration and these components are combined using the RSSQ method as outlined in the "GUIDE TO THE EXPRESSION OF UNCERTAINTY IN MEASUREMENT (GUM)."',
  main_report_note:
    'This report may not be reproduced, except in full, without the written permission from NBS Calibrations. Calibration procedures follow ISO 17025-2005 and NBS Calibrations procedure PR004. Copies of this procedure and calibration reports for the standards used are available upon request.',
}

/*
 * note changes in april 2014
 */
const pdf_report_ver2 = {
  statement_scale_class:
    '* Weight device accuracy class as determined from NIST Handbook 44, table 3. Class III scale tolerance is from NIST Handbook 44 table 6. Class I and Class II device tolerance are 1/4 of the 0.10% tolerance of the applied load as stated in the United States Pharmacopeia (USP) code or 3 divisions, whichever is larger.',
  calibration_note:
    'This report may not be reproduced, except in full, without the written permission from NBS Calibrations. Calibration procedures follow ISO 17025-2017 and NBS Calibrations procedure PR004. Copies of this procedure and calibration reports for the standards used are available upon request.',
  calibration_note_ynA2LACalRep:
    'The standards used to calibrate your equipment are traceable to the SI and NIST through the State of Minnesota Metrology Lab. The reported expanded uncertainty of measurement is stated as the standard uncertainty of measurement multiplied by the coverage factor k (k=2) such that the coverage probability corresponds to approximately 95%. The uncertainty value includes components for the resolution of the instrument receiving the calibration, the standard deviation of the repeatability test or an equivalent component and the standard uncertainty of the standards used to perform the calibration and these components are combined using the RSSQ method as outlined in the "GUIDE TO THE EXPRESSION OF UNCERTAINTY IN MEASUREMENT (GUM)."',
}

// Create Document Component
const MyDocument = (props: Props) => {
  // const [deviation, setDeviation] = useState('')
  // const [uncertainty, setUncertainty] = useState('')
  const { data } = props
  const {
    arrayClient,
    arrayEquipmentTest,
    arrayTechnician,
    arrayWeightSet,
    setCustomerSuppliedTolerance,
    setFooterText,
    UncertaintyData,
    techName,
  } = data
  // useEffect(() => {
  //   if (arrayEquipmentTest[0]) {
  //     var array = []
  //     if (arrayEquipmentTest[0].isTenRepeatabilityPoints == 1) {
  //       array = [
  //         arrayEquipmentTest[0]?.Wt1,
  //         arrayEquipmentTest[0]?.Wt2,
  //         arrayEquipmentTest[0]?.Wt3,
  //         arrayEquipmentTest[0]?.Wt4,
  //         arrayEquipmentTest[0]?.Wt5,
  //         arrayEquipmentTest[0]?.Wt6,
  //         arrayEquipmentTest[0]?.Wt7,
  //         arrayEquipmentTest[0]?.Wt8,
  //         arrayEquipmentTest[0]?.Wt9,
  //         arrayEquipmentTest[0]?.Wt10,
  //       ]
  //     } else {
  //       array = [
  //         arrayEquipmentTest[0]?.Wt1,
  //         arrayEquipmentTest[0]?.Wt2,
  //         arrayEquipmentTest[0]?.Wt3,
  //         arrayEquipmentTest[0]?.Wt4,
  //         arrayEquipmentTest[0]?.Wt5,
  //         arrayEquipmentTest[0]?.Wt6,
  //         arrayEquipmentTest[0]?.Wt7,
  //       ]
  //     }
  //     if (arrayEquipmentTest[0].repeatabilityFormulaVer2 == 1) {
  //       let standardDevition = StandardDeviationVer2(arrayEquipmentTest[0]?.Division, array, true)
  //       console.log('standard==', standardDevition)
  //       setDeviation(standardDevition)
  //       setUncertainty(
  //         UncertaintyCalculationVer2(
  //           standardDevition,
  //           arrayEquipmentTest[0].Division,
  //           UncertaintyData.UncertaintyParam
  //         )
  //       )
  //     } else {
  //       console.log('standard11==', StandardDeviation(array))

  //       setDeviation(StandardDeviation(array))
  //       setUncertainty(
  //         CalculateUncertaintyForRepeatability(
  //           arrayEquipmentTest[0].Division,
  //           UncertaintyData.UncertaintyParam
  //         )
  //       )
  //     }
  //   }
  // }, [])

  function getStandardDeviation(array: any[]): number {
    const n = array.length
    if (n <= 1) {
      throw new Error('Array must have more than one element to calculate standard deviation.')
    }

    const numbers = array.map((x) => Number(x))
    const mean = numbers.reduce((a, b) => a + b) / n

    console.log(numbers)
    console.log(
      Math.sqrt(numbers.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / (n - 1))
    )

    return Math.sqrt(numbers.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / (n - 1))
  }
  var divisionString = arrayEquipmentTest[0].Division.toString()
  var trimmedDivisionString = parseFloat(divisionString).toString()
  var devisionLenght = trimmedDivisionString.includes('.')
  ? trimmedDivisionString.split('.')[1].length
  : 0
  const getDeviation = () => {
    var array = []
    if (arrayEquipmentTest[0].isTenRepeatabilityPoints == 1) {
      array = [
        arrayEquipmentTest[0]?.Wt1,
        arrayEquipmentTest[0]?.Wt2,
        arrayEquipmentTest[0]?.Wt3,
        arrayEquipmentTest[0]?.Wt4,
        arrayEquipmentTest[0]?.Wt5,
        arrayEquipmentTest[0]?.Wt6,
        arrayEquipmentTest[0]?.Wt7,
        arrayEquipmentTest[0]?.Wt8,
        arrayEquipmentTest[0]?.Wt9,
        arrayEquipmentTest[0]?.Wt10,
      ]
    } else {
      array = [
        arrayEquipmentTest[0]?.Wt1,
        arrayEquipmentTest[0]?.Wt2,
        arrayEquipmentTest[0]?.Wt3,
        arrayEquipmentTest[0]?.Wt4,
        arrayEquipmentTest[0]?.Wt5,
        arrayEquipmentTest[0]?.Wt6,
        arrayEquipmentTest[0]?.Wt7,
      ]
    }

    let standardDeviation = getStandardDeviation(array)
    console.log('stan', standardDeviation, 0.41 * Number(arrayEquipmentTest[0].Division))

    let deviation =
      standardDeviation > 0.41 * Number(arrayEquipmentTest[0].Division)
        ? standardDeviation
        : 0.41 * Number(arrayEquipmentTest[0].Division)
    console.log(deviation)
    // Format the standard deviation
    var divisionString = arrayEquipmentTest[0].Division.toString()
    var trimmedDivisionString = parseFloat(divisionString).toString() // Strips trailing zeros
    var deviationCount = trimmedDivisionString.includes('.')
      ? trimmedDivisionString.split('.')[1].length
      : 0
    console.log('deviation count', deviationCount)
    deviation = Number(deviation.toPrecision(2))
    console.log(deviation)
    console.log(deviationCount, arrayEquipmentTest[0].Division)
    return [
      deviation,
      Number(
        CalculateUncertaintyForRepeatability(
          arrayEquipmentTest[0].Division,
          UncertaintyData.UncertaintyParam,
          deviation,
          arrayEquipmentTest[0].ScaleClass
        )
      ).toPrecision(2),
    ]
  }

  return (
    <Document>
      <Page size='A4' style={styles.page}>
       
            <Image source={toAbsoluteUrl('/media/pdf/pdf_logo.png')} style={styles.logo} />
     
        <Text style={styles.title}>9150 Isanti Street NE - Blaine, MN 55449</Text>
        <Text style={styles.title}>
          PHONE: (952) 881-7716 - (800) 722-5398 - FAX (952) 881-7309
        </Text>
        <Text style={styles.domain}>www.nbscalibrations.com</Text>
        <Text style={[{ fontFamily: 'Helvetica-Bold' }]}>
          Report No:
          <span style={{ fontFamily: 'Helvetica-Bold' }}>{arrayEquipmentTest[0]?.ReportNo}</span>
        </Text>
        <Text style={styles.subtitle}>Calibration certificate</Text>
        {
          arrayEquipmentTest[0]?.ynA2LACalRep == 1 && (
        <Image source={toAbsoluteUrl('/media/pdf/mark.jpg')} style={styles.mark} />
        )
      }
        <View style={styles.section}>
          <Text>
            Customer:{' '}
            <span style={{ fontFamily: 'Helvetica-Bold' }}>
              {arrayClient[0]?.ShipCompanyName}, {arrayClient[0]?.ShipAddres},{' '}
              {arrayClient[0]?.ShipCity}, {arrayClient[0]?.ShipState}
            </span>
          </Text>
          <Text>
            Customer ID:{' '}
            <span style={{ fontFamily: 'Helvetica-Bold' }}>{arrayClient[0]?.CustomerID}</span>{' '}
          </Text>
        </View>
        <View style={styles.section}>
          <Text>
            Date of Calibration:{' '}
            <span style={{ fontFamily: 'Helvetica-Bold' }}>
              {arrayEquipmentTest[0]?.CalibrationMonth}/{arrayEquipmentTest[0]?.CalibrationDate}/
              {arrayEquipmentTest[0]?.CalibrationYear}
            </span>
          </Text>
          <Text>
            Next Date of Calibration:{' '}
            <span style={{ fontFamily: 'Helvetica-Bold' }}>
              {arrayEquipmentTest[0]?.DateScheduled}
            </span>{' '}
          </Text>
          <Text>
            Technician:{' '}
            <span style={{ fontFamily: 'Helvetica-Bold' }}>
              {techName[0]?.firstname} {techName[0]?.lastname}
            </span>
          </Text>
        </View>
        <View style={styles.border_section}>
          <View style={styles.section}>
            <Text>
              Manufacturer:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>{arrayEquipmentTest[0]?.Mfg}</span>{' '}
            </Text>
            <Text>
              Model:
              <span style={{ fontFamily: 'Helvetica-Bold' }}>
                {' '}
                {arrayEquipmentTest[0]?.Model}
              </span>{' '}
            </Text>
            <Text>
              Serial#:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>
                {arrayEquipmentTest[0]?.SerialNumber}
              </span>{' '}
            </Text>
          </View>
          <View style={styles.section}>
            <Text>
              Capacity:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>
                {arrayEquipmentTest[0]?.Capacity}
              </span>{' '}
            </Text>
            <Text>
              Division Size(d):{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>
                {Number(arrayEquipmentTest[0]?.Division)}{' '}
                {arrayEquipmentTest[0]?.DivisionDual
                  ? `/ ${Number(arrayEquipmentTest[0].DivisionDual)}`
                  : ''}{' '}
              </span>
            </Text>
            <Text>
              Unit Id:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>
                {arrayEquipmentTest[0]?.UnitID}
              </span>
            </Text>
          </View>
          <View style={styles.section}>
            <Text>
              Units of measure**:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>{arrayEquipmentTest[0]?.UM}</span>{' '}
            </Text>
            <Text>
              Scale Class*:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>
                {arrayEquipmentTest[0]?.ScaleClass}{' '}
              </span>
            </Text>
          </View>
          <View style={styles.section}>
            <Text>
              Department:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>
                {arrayEquipmentTest[0]?.Department}
              </span>
            </Text>
            <Text>
              Location:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>
                {arrayEquipmentTest[0]?.Location}
              </span>{' '}
            </Text>
          </View>
          <View style={styles.section}>
            <Text>
              Site Calibration:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>
                {FormatConditionAsText(
                  Number(arrayEquipmentTest[0]?.isSiteCalibration),
                  'isSiteCalibration'
                )}
              </span>
            </Text>
            <Text>
              Site Temperature:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>
                {arrayEquipmentTest[0]?.Temperature}°
                <span style={{ textTransform: 'uppercase' }}>
                  {arrayEquipmentTest[0]?.TemperatureUnit}
                </span>
              </span>
            </Text>
            <Text>
              Condition:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>
                {arrayEquipmentTest[0]?.isWorking ? 'Working' : 'Not Working'},{' '}
                {arrayEquipmentTest[0]?.isClean ? 'Clean' : ''},{' '}
                {/* {arrayEquipmentTest[0]?.isDirty ? 'Dirty' : ''} */}
                {arrayEquipmentTest[0]?.isOutOfLevel ? 'Level' : ''}
                {arrayEquipmentTest[0]?.isSiteCalibration ? 'Site Calibration' : ''}
                {/* {arrayEquipmentTest[0]?.isDirty ? 'Dirty' : ''} */}
                {/* {FormatConditionAsText(
                  Number(arrayEquipmentTest[0]?.isSiteCalibration),
                  'isWorking'
                )} */}
              </span>
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={{ marginBottom: 3 }}>
              * Weight device accuracy class as determined from NIST Handbook 44, table 3. Class III
              scale tolerance is from NIST Handbook 44 table 6. Class I and Class II device
              tolerance are 1/4 of the 0.10% tolerance of the applied load as stated in the United
              States Pharmacopeia (USP) code or 3 divisions, whichever is larger. ** All units of
              measure are consistent throughout this calibration report unless otherwise noted.
            </Text>
          </View>
        </View>
        {arrayEquipmentTest[0]?.ynA2LACalRep == 1 && arrayEquipmentTest[0]?.ScaleClass != 'III' ? (
          <View>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Repeatability Checks</Text>
            <View style={{ marginTop: 3 }}>
       
              <PdfTable
              
  rows={
    arrayEquipmentTest[0].isTenRepeatabilityPoints == 1
      ? [
          1,
          parseFloat(arrayEquipmentTest[0]?.Wt1).toFixed(devisionLenght),
          2,
          parseFloat(arrayEquipmentTest[0]?.Wt2).toFixed(devisionLenght),
          3,
          parseFloat(arrayEquipmentTest[0]?.Wt3).toFixed(devisionLenght),
          4,
          parseFloat(arrayEquipmentTest[0]?.Wt4).toFixed(devisionLenght),
          5,
          parseFloat(arrayEquipmentTest[0]?.Wt5).toFixed(devisionLenght),
          6,
          parseFloat(arrayEquipmentTest[0]?.Wt6).toFixed(devisionLenght),
          7,
          parseFloat(arrayEquipmentTest[0]?.Wt7).toFixed(devisionLenght),
          8,
          parseFloat(arrayEquipmentTest[0]?.Wt8).toFixed(devisionLenght),
          9,
          parseFloat(arrayEquipmentTest[0]?.Wt9).toFixed(devisionLenght),
          10,
          parseFloat(arrayEquipmentTest[0]?.Wt10).toFixed(devisionLenght),
        ]
      : [
          1,
          parseFloat(arrayEquipmentTest[0]?.Wt1).toFixed(devisionLenght),
          2,
          parseFloat(arrayEquipmentTest[0]?.Wt2).toFixed(devisionLenght),
          3,
          parseFloat(arrayEquipmentTest[0]?.Wt3).toFixed(devisionLenght),
          4,
          parseFloat(arrayEquipmentTest[0]?.Wt4).toFixed(devisionLenght),
          5,
          parseFloat(arrayEquipmentTest[0]?.Wt5).toFixed(devisionLenght),
          6,
          parseFloat(arrayEquipmentTest[0]?.Wt6).toFixed(devisionLenght),
          7,
          parseFloat(arrayEquipmentTest[0]?.Wt7).toFixed(devisionLenght),
        ]
  }
  
                
              />
            </View>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>
              Equipment Standard Deviation:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>{getDeviation()[0]}</span>
            </Text>
          </View>
        ) : (
          <View>
            {/* Content to display if the condition is false */}
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Repeatability Checks</Text>
            <Text>
              Repeatability Checks:{' '}
              <span style={{ fontFamily: 'Helvetica-Bold' }}>
                {arrayEquipmentTest[0]?.isRepeatabilityOk === 1 ? 'Passed' : 'Fail'}
              </span>
            </Text>
          </View>
        )}
        {((arrayEquipmentTest[0]?.ynA2LACalRep == 1 &&
          (arrayEquipmentTest[0]?.ScaleClass == 'II' ||
            arrayEquipmentTest[0]?.ScaleClass == 'I')) ||
          (arrayEquipmentTest[0]?.ScaleClass == 'III' &&
            arrayEquipmentTest[0]?.ynA2LACalRep == 1)) && (
          <Text>
            Measurement Uncertainty: {console.log('here')}
            <span style={{ fontFamily: 'Helvetica-Bold' }}>{getDeviation()[1]}</span>
          </Text>
        )}
        <View style={styles.section}></View>
        <View style={styles.section}>
          <Text style={{ fontFamily: 'Helvetica-Bold' }}>Performance Checks-Span</Text>
          <Text>
            Customer Supplied Tolerance:
            <span style={{ fontFamily: 'Helvetica-Bold' }}>{setCustomerSuppliedTolerance}</span>
          </Text>
          <Text>
            Verified Zero:{' '}
            <span style={{ fontFamily: 'Helvetica-Bold' }}>
              {FormatConditionAsText(arrayEquipmentTest[0].isVerifiedZero, 'isVerifiedZero')}
            </span>
          </Text>
        </View>
        <View style={{ marginTop: 3 }}>
          <PerformanceCheckTable data={arrayEquipmentTest[0]} />
        </View>
        {console.log(arrayEquipmentTest[0].CornerLoadFront)}
        {!/\d/.test(arrayEquipmentTest[0].CornerLoadValue.trim()) ||
        arrayEquipmentTest[0].CornerLoadValue.includes('NA') ||
        arrayEquipmentTest[0].CornerLoadValue == ' ' ||
        arrayEquipmentTest[0].CornerLoadValue == '' ||
        arrayEquipmentTest[0].CornerLoadValue == 'NA ' ||
        arrayEquipmentTest[0].CornerLoadValue == 'NA' ||
        arrayEquipmentTest[0].CornerLoadValue == null ? (
          <></>
        ) : (
          <>
            <View style={{ marginTop: 3 }}>
              <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                Performance Checks - Eccentric load: (Mass Value:{' '}
                {arrayEquipmentTest[0].CornerLoadValue !== null
                  ? arrayEquipmentTest[0].CornerLoadValue
                  : '0'}
                )
              </Text>
            </View>
            <View style={{ marginTop: 3 }}>
              <PerformanceCheckTable1 data={arrayEquipmentTest[0]} />
            </View>
            {/* <View>
          <Svg height='200' width='500'>
            <Polyline points='250,150 250,50' stroke='black' strokeWidth={3} />
            <Polyline points='250,50 230,70' stroke='black' strokeWidth={3} />
            <Polyline points='250,50 270,70' stroke='black' strokeWidth={3} />
          </Svg>{' '}
        </View> */}
            <Text>
              <Image src={toAbsoluteUrl('/media/arrow.png')} />
              All AS LEFT mass values must be in-tolerance for span and cornerload performance
              checks as a whole to be in-tolerance. The determination in/out of tolerance takes into
              account the measurement uncertainty.
            </Text>
          </>
        )}
        <View style={{ marginTop: 5 }}>
          <Text style={{ fontFamily: 'Helvetica-Bold' }}>Calibration Standards</Text>
        </View>
        <View style={{ border: '1px solid black', padding: 4, marginTop: 4 }}>
          <Text>Weight Set Used: {arrayWeightSet.map((item) => item.WeightSet).toString()}</Text>
          <Text>
            Weight Set Calibration Due date:{' '}
            {arrayWeightSet.map((item) => moment(item.DateNextCal).format('MM/YYYY')).toString()}
          </Text>
          <Text style={{ fontSize: 9, marginTop: 4 }}>
            {arrayEquipmentTest[0].repeatabilityFormulaVer2 == 1
              ? pdf_report_ver2['calibration_note_ynA2LACalRep']
              : pdf_report['calibration_note_ynA2LACalRep']}
          </Text>
        </View>
        <View style={{ marginTop: 2 }}>
          <Text style={{ fontFamily: 'Helvetica-Bold' }}>Comments</Text>
          <Text>{arrayEquipmentTest[0].Comments}</Text>
        </View>
        <View style={{ marginTop: 4 }}>
          <Text style={{ fontSize: 9 }}>
            {arrayEquipmentTest[0].repeatabilityFormulaVer2 == 1
              ? pdf_report['main_report_note']
              : pdf_report['calibration_note']}
          </Text>
        </View>
        <View style={{ marginTop: 10 }}>
          <Text style={styles.title}>{setFooterText}</Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  )
}
export default MyDocument
