import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer } from 'react-leaflet';
import { PageTitle } from '../../../_metronic/layout/core';
import { getCustomerData } from './TerritoryMapCrud';
import { getValidCustomerShippingData } from './TerritoryMapCrud';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import { array } from 'yup';
import { KTSVG } from '../../../_metronic/helpers'
import FilterDropDown from './components/MapFilter';
import MapFilter from './components/MapFilter';
import { Card } from 'react-bootstrap-v5'
import { CustomerModel } from '../../../types';
import { getNBSCustomers } from '../customers/CustomersCRUD';
import { Customer } from '../../../types';
import CustomerDetails from '../nbs-admin/NewNBSAdmin';
import { check } from 'prettier';
let customerShippingData: Array<any>;

let markerArray: Array<L.Marker> = []; // Initialize as an empty array

interface CustomerDetails {
  CustomerID: string;
  CompanyName: string;
  ShipAddress: string;
  ShipCity: string;
  ShipState: string;
  ShipZip: string;
  ShipLatitude: string;
  ShipLongitude: string;
  ShipAddressUpdated: string;
}

interface SelectedCustomers {
  [key: string]: CustomerDetails | undefined;
}


function createRedPin(desiredWidth: number): L.Icon {
  // Descrition and review needed
  const originalWidth = 66;
  const originalHeight = 143;
  const scaleFactor = desiredWidth / originalWidth;
  const desiredHeight = Math.round(originalHeight * scaleFactor);

  const pin = L.icon({
    iconUrl: '/media/logos/pin.png',
    iconSize: [desiredWidth, desiredHeight],
    iconAnchor: [desiredWidth >> 1, desiredHeight], //bitshift of 1 
    popupAnchor: [-3, -76]
  });
  return pin;
}





const TerritoryMapPage: React.FC = () => {
  const [map, setMap] = useState<L.Map | null>(null);
  const [slug, setSlug] = useState<string>('');
  const [customerIds, setCustomerIds] = useState<string[]>()
  const [filterVersion, setFilterVersion] = useState(0); // Holds the version of the filter
  const [currentFilters, setCurrentFilters] = useState({});
  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [customers, setCustomers] = useState<CustomerModel[]>([])
  const [dropDownCustomers, setDropDownCustomers] = useState<Customer[]>([{ id: '', name: '' }])
  const [dropDownStartDate, setDropDownStartDate] = useState<any>('')
  const [dropDownEndDate, setDropDownEndDate] = useState<any>()
  const [selectedCustomers, setSelectedCustomers] = useState<SelectedCustomers>({});
  const [selectedCustomers2, setSelectedCustomers2] = useState<SelectedCustomers>({});

  function bindPopupOnClick(marker: L.Marker, customerData: CustomerDetails) {
    const isChecked = !!selectedCustomers[customerData.CustomerID];  // Convert presence of customer data to a boolean
    console.log(selectedCustomers,"here")
    let customerPopupInfo = `
      <div style="text-align: left; font-size: 14px; line-height: 1.5;">
        <b>${customerData.CompanyName}</b><br />
        Shipping ID: ${customerData.CustomerID}<br />
        Shipping Address: ${customerData.ShipAddress}<br />
        Ship City: ${customerData.ShipCity}<br />
        Ship State: ${customerData.ShipState}<br />
        Ship Zip: ${customerData.ShipZip}<br />
        Ship Latitude: ${customerData.ShipLatitude}<br />
        Ship Longitude: ${customerData.ShipLongitude}<br />
        Ship Address Updated: ${customerData.ShipAddressUpdated}<br /><br />
        <label for="select-${customerData.CustomerID}">
        Select this location
        <input type="checkbox" id="select-${customerData.CustomerID}" name="selectedCustomer" value="${customerData.CustomerID}" ${isChecked ? 'checked' : ''} style="margin-right: 5px; width: 40px;" >
      </label>
      </div>
    `;

    return customerPopupInfo;
}
  
  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }



  const fetchCustomerData = async (filters: any, from: any) => {
    try {
        const response = await getValidCustomerShippingData(filters);
        customerShippingData = response.data.customerDetails;

        if (typeof customerShippingData === 'string') {
            console.log("There are no valid pin locations");
            return;
        }

        const existingMarkers = new Map(markerArray.map(marker => [marker.options.customerID, marker]));

        // Clear existing markers from the map
        if (markerArray && markerArray.length > 0) {
            markerArray.forEach(marker => {
                if (map && marker) {
                    marker.removeFrom(map);
                }
            });
            markerArray = [];
        }

        customerShippingData.forEach((location: any) => {
            if (location != undefined && location.ShipAddressUpdated) {
                const pin = createRedPin(15);
                const latitude = parseFloat(location.ShipLatitude);
                const longitude = parseFloat(location.ShipLongitude);
                const marker = L.marker([latitude, longitude], { icon: pin });

                marker.on('click', () => {
                    if (marker.getPopup()) {
                        marker.unbindPopup();
                    }

                    const popupContent = bindPopupOnClick(marker, location);
                    const popup = marker.bindPopup(popupContent).openPopup();
                    
                    popup.on('popupopen', () => {
                        const checkbox = document.getElementById(`select-${location.CustomerID}`) as HTMLInputElement;

                        if (checkbox) {
                            checkbox.addEventListener('change', () => {
                                handleCheckboxChange(
                                    location.CompanyName,
                                    location.CustomerID,
                                    location.ShipAddress,
                                    location.ShipCity,
                                    location.ShipState,
                                    location.ShipZip,
                                    location.ShipLatitude,
                                    location.ShipLongitude,
                                    location.ShipAddressUpdated,
                                    checkbox.checked
                                );
                            });
                        }
                    });
                });

                if (map) {
                    marker.addTo(map);
                    markerArray.push(marker);
                }
            }
        });
    } catch (error) {
        console.error('Error fetching customer data:', error);
    }
};
useEffect(() => {
  if (map === null) {

    return;
  }

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(map);
  
  console.log(selectedCustomers2)
  fetchCustomerData(currentFilters,"2");
}, [selectedCustomers2]);



useEffect(() => {
  if (map === null) {
      return;
  }

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(map);

  fetchCustomerData(currentFilters, "1");
}, [map, filterVersion, currentFilters]);

  const handleCheckboxChange = (
    CompanyName: string, // Use specific types instead of 'any' for better type checking
    CustomerID: string,
    ShipAddress: string,
    ShipCity: string,
    ShipState: string,
    ShipZip: string,
    ShipLatitude: string,
    ShipLongitude: string,
    ShipAddressUpdated: string,
    isChecked: boolean
  ) => {
    console.log(isChecked)
    console.log(CompanyName)
    setSelectedCustomers2(prev => {
      const newState = { ...prev };
      if (isChecked) {
        // Store the full customer data with relevant properties only
        newState[CustomerID] = {
          CompanyName: CompanyName,
          CustomerID: CustomerID,
          ShipAddress: ShipAddress,
          ShipCity: ShipCity,
          ShipState: ShipState,
          ShipZip: ShipState,
          ShipLatitude: ShipLatitude,
          ShipLongitude: ShipLongitude,
          ShipAddressUpdated: ShipAddressUpdated
          // Add other necessary properties if required
        };
      } else {
        
        // Use 'CustomerID' directly to delete the entry if unchecked
       delete newState[CustomerID];
      }
      return newState;
    });
  };
  


  // Adjust handleFilter to use async-await
  const handleFilter = async (dropdownFilters: any, fromSearch: boolean) => {
    if (!fromSearch) {
      // Directly using dropdownFilters to set currentFilters
      const newFilters = { // 1. Filters is getting created here
        searchBar: slug,
        startDate: dropdownFilters.startDate,
        endDate: dropdownFilters.endDate,
        selectedCustomers: dropdownFilters.selectedCustomers
      };
      setDropDownCustomers(dropdownFilters.selectedCustomers)
      setDropDownEndDate(dropdownFilters.endDate)
      setDropDownStartDate(dropdownFilters.startDate)
      setCurrentFilters(newFilters);
    } else {
      // If fromSearch is true, use existing dropdown states
      const filters = {
        searchBar: slug,
        startDate: dropDownStartDate,
        endDate: dropDownEndDate,
        selectedCustomers: dropDownCustomers
      };
      
      setCurrentFilters(filters);
    }
  
  
  }

  useEffect(() => {
    // Filters use effect
    //console.log(selectedCustomers2,"here")
  }, [selectedCustomers2]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Territory Map</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className='border-0 pt-6'>
          <Card.Title>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search'
                value={slug}
                onChange={(e: any) => setSlug(e.target.value)}
              />
               <button className='btn btn-sm btn-primary' style={{ marginLeft: '10px' }} onClick={() => handleFilter({}, true)}>Search</button>
            </div>
          </Card.Title>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>

              <div className='filter-dropdown ms-3'>
                <button
                  type='button'
                  className='btn btn-light-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                  ref={filterRef}
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <MapFilter
                  handleCancelCustomer={handleCancelCustomer}
                  handleFilter={handleFilter}
                />

              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0 service-quotes-body'>
          <div>
            <MapContainer
              id="map"
              center={[37.8, -96]}
              zoom={4}
              style={{ height: '1000px' }}
              whenCreated={setMap}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </MapContainer>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default TerritoryMapPage;